export default ({ instance }) => {
	return {
		ultimasNoticias() {
			return instance({
				method: "get",
				url: "noticias/noticias",
			});
		},
		linksProdutos() {
			return instance({
				method: "get",
				url: "noticias/link-produto",
			});
		},
	};
};
