import store from "@/store";
import API from "@/api";

export default [
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/LoginView.vue"),
		meta: {
			description: "AUTENTICACAO",
			requiresAuth: false,
		},
	},

	{
		path: "/esqueceusenha",
		name: "esqueceusenha",
		component: () => import("@/views/recuperarSenha/EsqueceuSenhaView.vue"),
		meta: {
			description: "RECUPERAR SENHA",
			requiresAuth: false,
		},
	},
	{
		path: "/novasenha/:token",
		name: "novasenha",
		component: () => import("@/views/recuperarSenha/NovaSenhaView.vue"),
		meta: {
			description: "NOVA SENHA",
			requiresAuth: false,
		},
		beforeEnter: (to, from, next) => {
			API.verificarValidadeToken(to.params.token)
				.then((response) => {
					if (response.data.result) {
						next();
					} else {
						next("/pagina-nao-encontrada");
					}
				})
				.catch((error) => {
					next("/login");
				});
		},
	},
	{
		path: "/descadastrar/:email",
		name: "descadastrar",
		component: () => import("@/views/DescadastrarEmailView.vue"),
		meta: {
			description: "DESCADASTRAR",
			requiresAuth: false,
		},
	},
];
