import axios from "axios";
import store from "@/store";
import autenticacao from "./portal-jornada-cliente-api/autenticacao";
import juridico from "./portal-jornada-cliente-api/juridico";
import distribuicao from "./portal-jornada-cliente-api/distribuicao";
import acessos from "./portal-jornada-cliente-api/acessos";
import atendimento from "./portal-jornada-cliente-api/atendimento";
import andamento from "./portal-jornada-cliente-api/andamento";
import intimacoes from "./portal-jornada-cliente-api/intimacoes";
import integracao from "./portal-jornada-cliente-api/integracao";
import analytics from "./portal-jornada-cliente-api/analytics";
import protocolo from "./portal-jornada-cliente-api/protocolo";
import financeiro from "./portal-jornada-cliente-api/financeiro";
import logPortal from "./portal-jornada-cliente-api/logPortal";
import usuarios from "./portal-jornada-cliente-api/usuarios";
import home from "./portal-jornada-cliente-api/home";
import avisos from "./portal-jornada-cliente-api/avisos";
import dje from "./portal-jornada-cliente-api/dje";
import djeExterno from "./portal-dje-api/dje";

const instance = axios.create({
	baseURL: process.env.VUE_APP_PORTAL_JORNADA_CLIENTE_API,
});

const instanceDje = axios.create({
	baseURL: process.env.VUE_APP_PORTAL_DJE_API,
});

// instance.defaults.headers.post["Content-Type"] = "json";
// instance.defaults.headers.put["Content-Type"] = "json";
instance.defaults.responseType = "json";
instance.interceptors.request.use((config) => {
	if (store.state.autenticacao.token)
		config.headers[
			"Authorization"
		] = `Bearer ${store.state.autenticacao.token}`;

	return config;
});

export default {
	...autenticacao({ instance }),
	...juridico({ instance }),
	...distribuicao({ instance }),
	...acessos({ instance }),
	...atendimento({ instance }),
	...andamento({ instance }),
	...intimacoes({ instance }),
	...integracao({ instance }),
	...analytics({ instance }),
	...protocolo({ instance }),
	...financeiro({ instance }),
	...logPortal({ instance }),
	...usuarios({ instance }),
	...home({ instance }),
	...avisos({ instance }),
	...dje({ instance }),
	...djeExterno({ instanceDje }),
};
