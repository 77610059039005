export default [
	{
		path: "/dje",
		name: "Kurier DJE",
		component: () => import("@/layouts/LayoutDje.vue"),
		meta: {
			description: "Kurier DJE",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/dje/comunicacao-judicial",
				name: "comunicacaoJudicial",
				component: () =>
					import("@/views/dje/ComunicacaoJudicialDjeView.vue"),
				meta: {
					description: "COMUNICAÇÃO JUDICIAL",
					requiresAuth: true,
					tituloMenu: "Kurier DJE",
				},
			},
			{
				path: "/dje/dados-cadastrais",
				name: "cadastroCertificado",
				component: () =>
					import("@/views/dje/CadastroCertificadoDjeView.vue"),
				meta: {
					description: "CADASTRO CERTIFICADO",
					requiresAuth: true,
					tituloMenu: "Kurier DJE",
				},
			},
		],
	},
];
