export default ({ instance }) => {
	return {
		relatorioPorMesAnoAnalytics(filtro) {
			return instance({
				method: "post",
				url: "homeanalytics/mes-ano",
				data: filtro,
			});
		},
        relatorioPorTermoAnalytics(filtro) {
			return instance({
				method: "post",
				url: "homeanalytics/termo",
				data: filtro,
			});
		},
		relatorioPorFuncionalidadeAnalytics(filtro) {
			return instance({
				method: "post",
				url: "homeanalytics/funcionalidade",
				data: filtro,
			});
		},
		relatorioPorFiltroAnalytics(filtro) {
			return instance({
				method: "post",
				url: "homeanalytics/filtro",
				data: filtro,
			});
		}
	};
};
