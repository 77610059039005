export default ({ instance }) => {
	return {
		ExportardadosCompletosExcelIntimacoes(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "homeintimacoes/exportar-tabela-excel",
				data: filtro,
			});
		},

		relatorioPorMesAnoIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/mes-ano",
				data: filtro,
			});
		},
		relatorioPorJusticaIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/justica",
				data: filtro,
			});
		},
		relatorioPorUfIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/uf",
				data: filtro,
			});
		},
		relatorioPorPerfilIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/perfil",
				data: filtro,
			});
		},

		relatorioMesAnoPorFiltroIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/mes-ano-filtro",
				data: filtro,
			});
		},

		relatorioPerfilPorFiltroIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/perfil-filtro",
				data: filtro,
			});
		},

		relatorioGrupoOperacionalPorFiltroIntimacoes(filtro) {
			return instance({
				method: "post",
				url: "homeintimacoes/grupo-operacional-filtro",
				data: filtro,
			});
		},
	};
};
