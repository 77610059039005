export default [
	{
		path: "/analytics",
		name: "analytics",
		component: () => import("@/layouts/LayoutAnalytics.vue"),
		meta: {
			description: "PORTAL ANALYTICS",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/analytics/dashboard",
				name: "dashboardAnalytics",
				component: () =>
					import("@/views/analytics/PainelAnalyticsView.vue"),
				meta: {
					description: "HOME",
					requiresAuth: true,
					tituloMenu: "Kurier Analytics",
				},
			},
			{
				path: "/analytics/portal-analytics",
				name: "portalAnalytics",
				component: () =>
					import("@/views/analytics/PortalAnalyticsView.vue"),
				meta: {
					description: "PORTAL ANALYTICS",
					requiresAuth: true,
					tituloMenu: "Kurier Analytics",
				},
			},
		],
	},
];
