export default [
	{
		path: "/distribuicao",
		name: "distribuicao",
		component: () => import("@/layouts/LayoutDistribuicao.vue"),
		meta: {
			description: "Kurier Distribuição",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/distribuicao/dashboard",
				name: "dashboardDistribuicao",
				component: () =>
					import("@/views/distribuicao/PainelDistribuicaoView.vue"),
				meta: {
					description: "HOME",
					requiresAuth: true,
					tituloMenu: "Kurier Distribuição",
				},
			},
			{
				path: "/distribuicao/dados-cadastrais",
				name: "dadosCadastraisDistribuicao",
				component: () =>
					import(
						"@/views/distribuicao/DadosCadastraisDistribuicaoView.vue"
					),
				meta: {
					description: "CADASTROS OPERACIONAIS",
					requiresAuth: true,
					tituloMenu: "Kurier Distribuição",
				},
			},
			{
				path: "/distribuicao/distribuicoes",
				name: "distribuicoesDistribuicao",
				component: () =>
					import(
						"@/views/distribuicao/DistribuicoesDistribuicaoView.vue"
					),
				meta: {
					description: "DISTRIBUICOES",
					requiresAuth: true,
					tituloMenu: "Kurier Distribuição",
				},
			},

			{
				path: "/distribuicao/monitoramentos/novo-monitoramento",
				name: "novoMonitoramentoDistribuicao",
				component: () =>
					import(
						"@/views/monitoramentos/distribuicao/NovoMonitoramentoDistribuicaoView.vue"
					),
				meta: {
					description: "NOVO MONITORAMENTO",
					requiresAuth: true,
					tituloMenu: "Kurier Distribuição",
					requiresPermission: "produtosCadastro",
				},
			},
		],
	},
];
