export default ({ instance }) => {
	return {
		//Dashboard
		ExportardadosCompletosExcelIntegracao(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "homeintegracao/exportar-tabela-excel",
				data: filtro,
			});
		},

		relatorioMesAnoPorFiltroIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/mes-ano-filtro",
				data: filtro,
			});
		},

		relatorioTermoPorFiltroIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/termo-filtro",
				data: filtro,
			});
		},

		relatorioGrupoOperacionalPorFiltroIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/grupo-operacional-filtro",
				data: filtro,
			});
		},

		buscarSubGrupoIntegracao(grupoOperacional) {
			return instance({
				method: "get",
				url: "homeintegracao/" + grupoOperacional,
			});
		},

		relatorioPorJusticaIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/justica",
				data: filtro,
			});
		},

		relatorioPorMesAnoIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/mes-ano",
				data: filtro,
			});
		},

		relatorioPorUfIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/uf",
				data: filtro,
			});
		},

		relatorioPorTermoIntegracao(filtro) {
			return instance({
				method: "post",
				url: "homeintegracao/termo",
				data: filtro,
			});
		},

		//Processos Monitorados
		consultarProcessosMonitoradosIntegracao(filtro) {
			return instance({
				method: "post",
				url: "processosmonitoradosintegracao/processos-monitorados",
				data: filtro,
			});
		},

		exportarProcessosMonitoradosIntegracao(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "processosmonitoradosintegracao/exportar-processos-monitorados",
				data: filtro,
			});
		},

		//Publicações
		publicacoesIntegracao(publicacoes) {
			return instance({
				method: "post",
				url: "publicacoesintegracao/publicacoes",
				data: publicacoes,
			});
		},

		publicacoesPorConteudoIntegracao(conteudo) {
			return instance({
				method: "post",
				url: "publicacoesintegracao/publicacoes-por-conteudo",
				data: conteudo,
			});
		},

		publicacoesPorCodigoIntegracao(codigo) {
			return instance({
				method: "post",
				url: "publicacoesintegracao/publicacoes-por-codigo",
				data: codigo,
			});
		},

		textoPublicacaoCompeltoIntegracao(codigo) {
			return instance({
				method: "post",
				url: "publicacoesintegracao/teor-publicacao",
				data: codigo,
			});
		},

		ExportarPublicacoesExcelIntegracao(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesintegracao/exportar-publicacoes-excel",
				data: filtro,
			});
		},

		ExportarPublicacoesJsonIntegracao(filtro) {
			return instance({
				method: "post",
				url: "publicacoesintegracao/exportar-publicacoes-json",
				data: filtro,
			});
		},

		ExportarPublicacoesXmlIntegracao(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesintegracao/exportar-publicacoes-xml",
				data: filtro,
			});
		},

		ExportarPublicacoesDocxIntegracao(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "publicacoesintegracao/exportar-publicacoes-docx",
				data: filtro,
			});
		},
	};
};
