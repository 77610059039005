export default ({ instanceDje }) => {
	return {
		uploadCertificado(certificado, arquivo) {
			const parametros = new URLSearchParams({
				grupoOperacional: certificado.grupoOperacional,
				email: certificado.email,
				password: certificado.senha,
				idCliente: certificado.idCliente,
			}).toString();

			return instanceDje({
				method: "post",
				url: "kurierdje/inserirCertificado?" + parametros,
				data: arquivo,
			});
		},

		removerCertificado(nome) {
			const parametros = new URLSearchParams({
				certificateName: nome,
			}).toString();

			return instanceDje({
				method: "post",
				url: "kurierdje/deletarCertificado?" + parametros,
			});
		},
	};
};
