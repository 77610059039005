export default ({ instance }) => {
	return {
		inserirLogPortal(dadosLog) {
			return instance({
				method: "post",
				url: "logacoes/inserir-log",
				data: dadosLog,
			});
		},

		exportarAuditoria(filtro) {
			return instance({
				responseType: "blob",
				method: "post",
				url: "logacoes/exportar-auditoria",
				data: filtro,
			});
		},
	};
};
