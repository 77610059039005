import API from "@/api";
import defaultState from "./state";
import { DO_LOGIN, DO_LOGOUT, SET_AUTENTICACAO } from "./types";

export default {
	[DO_LOGIN]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			API.fazerLogin(payload.login)
				.then((res) => {
					commit(SET_AUTENTICACAO, res.data.result);

					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[DO_LOGOUT]({ commit }) {
		return new Promise((resolve, reject) => {
			commit(SET_AUTENTICACAO, {
				...defaultState,
			});

			resolve();
		});
	},
};
