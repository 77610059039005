export default ({ instance }) => {
	return {
		login(usuario) {
			return instance({
				method: "post",
				url: "autenticacao",
				data: usuario,
			});
		},

		autenticarEmailSenha(usuario) {
			return instance({
				method: "post",
				url: "autenticacao/autenticar-email-senha",
				data: usuario,
			});
		},

		fazerLogin(usuario) {
			return instance({
				method: "post",
				url: "autenticacao/fazer-login",
				data: usuario,
			});
		},

		linkNovaSenha(acao) {
			return instance({
				method: "post",
				url: "autenticacao/email-criar-senha",
				data: acao,
			});
		},

		verificarValidadeToken(token) {
			return instance({
				method: "get",
				url: "autenticacao/verificar-token/" + token,
			});
		},

		cadastrarNovaSenha(senha) {
			return instance({
				method: "post",
				url: "autenticacao/nova-senha",
				data: senha,
			});
		},

		aceiteTermoUso(email) {
			return instance({
				method: "post",
				url: "autenticacao/aceite-termo",
				data: email,
			});
		},

		responderPesquisaSatisfacao(resposta) {
			return instance({
				method: "post",
				url: "autenticacao/pesquisa-satisfacao",
				data: resposta,
			});
		},

		consultarEmailValido(email) {
			return instance({
				method: "get",
				url: "autenticacao/consultar-email/" + email,
			});
		},
	};
};
