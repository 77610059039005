import defaultState from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
	state: () => ({ ...defaultState }),
	getters,
	actions,
	mutations,
};
