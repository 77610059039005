export default [
	{
		path: "/andamento",
		name: "andamento",
		component: () => import("@/layouts/LayoutAndamento.vue"),
		meta: {
			description: "Kurier Andamento",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/andamento/dashboard",
				name: "dashboardAndamento",
				component: () =>
					import("@/views/andamento/PainelAndamentoView.vue"),
				meta: {
					description: "HOME",
					requiresAuth: true,
					tituloMenu: "Kurier Andamento",
				},
			},
			{
				path: "/andamento/processosmonitorados",
				name: "processosMonitoradosAndamento",
				component: () =>
					import(
						"@/views/andamento/ProcessosMonitoradosAndamentoView.vue"
					),
				meta: {
					description: "CADASTROS OPERACIONAIS",
					requiresAuth: true,
					tituloMenu: "Kurier Andamento",
				},
			},
			{
				path: "/andamento/andamentos",
				name: "andamentosAndamento",
				component: () =>
					import("@/views/andamento/AndamentosAndamentoView.vue"),
				meta: {
					description: "ANDAMENTOS",
					requiresAuth: true,
					tituloMenu: "Kurier Andamento",
				},
			},
		],
	},
];
