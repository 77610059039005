export default [
	{
		path: "/intimacoes",
		name: "intimacoes",
		component: () => import("@/layouts/LayoutIntimacoes.vue"),
		meta: {
			description: "Kurier Intimações",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/intimacoes/dashboard",
				name: "dashboardIntimacoes",
				component: () =>
					import("@/views/intimacoes/PainelIntimacoesView.vue"),
				meta: {
					description: "HOME",
					requiresAuth: true,
					tituloMenu: "Kurier Intimações",
				},
			},
			{
				path: "/intimacoes/portal-intimacoes",
				name: "portalIntimacoes",
				component: () =>
					import("@/views/intimacoes/PortalIntimacoesView.vue"),
				meta: {
					description: "PORTAL INTIMACOES",
					requiresAuth: true,
					tituloMenu: "Kurier Intimações",
				},
			},
		],
	},
];
