import juridico from "@/router/juridico";
import distribuicao from "@/router/distribuicao";
import portal from "@/router/portal";
import andamento from "@/router/andamento";
import analytics from "@/router/analytics";
import intimacoes from "@/router/intimacoes";
import integracao from "@/router/integracao";
import dje from "@/router/dje";

export default [
	{
		path: "/",
		name: "principal",
		component: () => import("@/layouts/LayoutPrincipal.vue"),
		meta: {
			description: "principal",
			requiresAuth: true,
		},
		children: [
			...juridico,
			...distribuicao,
			...portal,
			...andamento,
			...analytics,
			...integracao,
			...intimacoes,
			...dje,
		],
	},
];
