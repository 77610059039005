export default ({ instance }) => {
	return {
		consultarFaturamento(filtro) {
			return instance({
				method: "post",
				url: "financeiro/faturamento",
				data: filtro,
			});
		},
	};
};
