export default ({ instance }) => {
	return {
		novoProtocolo(protocolo) {
			return instance({
				contentType: "multipart/form-data",
				method: "post",
				url: "protocolo/novo-protocolo",
				data: protocolo,
			});
		},

		alterarDadosContrato(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/alteracao-dados-contrato",
				data: protocolo,
			});
		},

		adicionarContato(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/adicionar-contato",
				data: protocolo,
			});
		},

		alterarContato(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/alterar-contato",
				data: protocolo,
			});
		},

		excluirContato(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/excluir-contato",
				data: protocolo,
			});
		},

		alterarDadosPerfil(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/alteracao-dados-perfil",
				data: protocolo,
			});
		},

		novoTermoJuridico(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/novo-termo-juridico",
				data: protocolo,
			});
		},

		novoTermoDistribuicao(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/novo-termo-distribuicao",
				data: protocolo,
			});
		},

		alterarFontes(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/alterar-fontes-termo",
				data: protocolo,
			});
		},

		recuperarProtocolos(filtro) {
			return instance({
				method: "post",
				url: "protocolo/recuperar-protocolos",
				data: filtro,
			});
		},

		cancelarProtocolo(idProtocolo) {
			return instance({
				method: "post",
				url: "protocolo/cancelar-protocolo/" + idProtocolo,
			});
		},

		alterarTermoJuridico(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/alterar-monitoramento",
				data: protocolo,
			});
		},

		alterarTermoDistribuicao(protocolo) {
			return instance({
				method: "post",
				url: "protocolo/alterar-monitoramento",
				data: protocolo,
			});
		},
	};
};
