export default [
	{
		path: "/juridico",
		name: "Kurier Jurídico",
		component: () => import("@/layouts/LayoutJuridico.vue"),
		meta: {
			description: "Kurier Jurídico",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/juridico/dashboard",
				name: "dashboardJuridico",
				component: () =>
					import("@/views/juridico/PainelJuridicoView.vue"),
				meta: {
					description: "HOME",
					requiresAuth: true,
					tituloMenu: "Kurier Jurídico",
				},
			},
			{
				path: "/juridico/dados-cadastrais",
				name: "dadosCadastraisJuridico",
				component: () =>
					import("@/views/juridico/DadosCadastraisJuridicoView.vue"),
				meta: {
					description: "CADASTROS OPERACIONAIS",
					requiresAuth: true,
					tituloMenu: "Kurier Jurídico",
				},
			},
			{
				path: "/juridico/publicacoes",
				name: "publicacoesJuridico",
				component: () =>
					import("@/views/juridico/PublicacoesJuridicoView.vue"),
				meta: {
					description: "PUBLICACOES",
					requiresAuth: true,
					tituloMenu: "Kurier Jurídico",
				},
			},
			{
				path: "/juridico/monitoramentos/novo-monitoramento",
				name: "novoMonitoramentoJuridico",
				component: () =>
					import(
						"@/views/monitoramentos/juridico/NovoMonitoramentoJuridicoView.vue"
					),
				meta: {
					description: "NOVO MONITORAMENTO",
					requiresAuth: true,
					tituloMenu: "Kurier Jurídico",
					requiresPermission: "produtosCadastro",
				},
			},
		],
	},
];
