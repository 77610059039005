export default ({ instance }) => {
	return {
		consultarTodosAvisos(filtro) {
			return instance({
				method: "post",
				url: "avisos/listar",
				data: filtro,
			});
		},

		alterarAvisos(filtro) {
			return instance({
				method: "post",
				url: "avisos/editar",
				data: filtro,
			});
		},
	};
};
