export default [
	{
		path: "/integracao",
		name: "integracao",
		component: () => import("@/layouts/LayoutIntegracao.vue"),
		meta: {
			description: "Kurier Integração",
			requiresAuth: true,
			info: "produto",
			requiresPermission: "produtos",
		},
		children: [
			{
				path: "/integracao/dashboard",
				name: "dashboardIntegracao",
				component: () =>
					import("@/views/integracao/PainelIntegracaoView.vue"),
				meta: {
					description: "HOME",
					requiresAuth: true,
					tituloMenu: "Kurier Integração",
				},
			},
			{
				path: "/integracao/processosmonitorados",
				name: "processosMonitoradosIntegracao",
				component: () =>
					import(
						"@/views/integracao/ProcessosMonitoradosIntegracaoView.vue"
					),
				meta: {
					description: "PROCESSOS MONITORADOS",
					requiresAuth: true,
					tituloMenu: "Kurier Integração",
				},
			},
			{
				path: "/integracao/publicacoes",
				name: "publicacoesIntegracao",
				component: () =>
					import("@/views/integracao/PublicacoesIntegracaoView.vue"),
				meta: {
					description: "PUBLICACOES",
					requiresAuth: true,
					tituloMenu: "Kurier Integração",
				},
			},
		],
	},
];
