export default ({ instance }) => {
	return {
		buscarTodosUsuarios(usuario, status) {
			return instance({
				method: "get",
				url: `usuarios/buscarTodosUsuarios/usuario?usuario=${usuario}&status=${status}`,
			});
		},
		adicionarNovoUsuarioComContratosEPermissoes(usuario) {
			return instance({
				method: "post",
				url: "usuarios/adicionar",
				data: usuario,
			});
		},
		atualizarStatus(usuario) {
			return instance({
				method: "post",
				url: "usuarios/status",
				data: usuario,
			});
		},

		consultarDadosRestricao(filtro) {
			return instance({
				method: "post",
				url: "usuarios/consultar-dados-restricao",
				data: filtro,
			});
		},

		atualizarNotificacoesVolumetria(data) {
			return instance({
				method: "post",
				url: "usuarios/atualizar-notificacao-volumetria",
				data: data,
			});
		},
	};
};
