export default {
	isAuthenticated: (state) => (state.token ? true : false),
	isEmailPasswordConfirmed: (state) => state.autenticado,
	getAuthenticatedUser: (state) => {
		return {
			nome: state.nome,
			cliente: state.cliente,
			login: state.login,
			email: state.email,
			contrato: state.contrato,
			produto: state.produto,
			perfil: state.perfil,
			token: state.token,
			produtos: state.produtos,
			telefone: state.telefone,
			aceiteTermoUso: state.aceiteTermoUso,
			respondeuPesquisa: state.respondeuPesquisa,
			naoLembrarPesquisa: state.naoLembrarPesquisa,
			dataTermoAceito: state.dataTermoAceito,
			horarioNotificacao8h: state.horarioNotificacao8h,
			horarioNotificacao12h: state.horarioNotificacao12h,
			horarioNotificacao17h: state.horarioNotificacao17h,
		};
	},
	getAvisos: (state) => state.avisos,
	getContratosPerfil: (state) => state.contratosPerfil,
	getTodosContratosPerfil: (state) => state.todosContratosPerfil,
	getProduto: (state) => state.produto,
	getContrato: (state) => state.contrato,
	getClientes: (state) => state.clientes,
	getIdClienteSelecionado: (state) => state.idClienteSelecionado,
	getPublicacoesNaoLidas: (state) => state.publicacoesNaoLidas,
	getProcessosNaoLidos: (state) => state.processosNaoLidos,
	getFormularioAtivo: (state) => state.formularioAtivo,
};
