import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import { DO_LOGOUT } from "@/store/modules/autenticacao/types";

import "@/assets/styles/theme.less";

const app = createApp(App);

app.config.globalProperties.$logout = () => {
	store.dispatch(DO_LOGOUT);

	router.push({ name: "login" });

	return;
};

app.use(Antd).use(store).use(router).mount("#app");
