export default ({ instance }) => {
	return {
		buscarAcessos(acesso) {
			return instance({
				method: "post",
				url: "acessos/buscar",
				data: acesso,
			});
		},
		adicionarAcessos(acesso) {
			return instance({
				method: "post",
				url: "acessos/adicionar",
				data: acesso,
			});
		},
		editarAcessos(acesso) {
			return instance({
				method: "post",
				url: "acessos/atualizar",
				data: acesso,
			});
		},
		excluirAcessos(acesso) {
			return instance({
				method: "post",
				url: "acessos/remover",
				data: acesso,
			});
		},
		listarHistorico(acesso) {
			return instance({
				method: "post",
				url: "acessos/buscarhistorico",
				data: acesso,
			});
		},
	};
};
