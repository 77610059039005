export default {
	nome: null,
	cliente: null,
	email: null,
	login: null,
	produto: null,
	contrato: null,
	perfil: null,
	token: null,
	avisos: 0,
	autenticado: false,
	contratosPerfil: null,
	clientes: null,
	produtos: null,
	todosContratosPerfil: null,
	idClienteSelecionado: null,
	telefone: null,
	aceiteTermoUso: null,
	respondeuPesquisa: null,
	naoLembrarPesquisa: null,
	publicacoesNaoLidas: 0,
	processosNaoLidos: 0,
	formularioAtivo: false,
	dataTermoAceito: null,
	horarioNotificacao8h: null,
	horarioNotificacao12h: null,
	horarioNotificacao17h: null,
};
