import defaultState from "./state";
import { SET_AUTENTICACAO, SET_LOGOUT } from "./types";

export default {
	[SET_AUTENTICACAO](state, payload) {
		state.nome = payload.nome;
		state.cliente = payload.cliente;
		state.email = payload.email;
		state.login = payload.login;
		state.produto = payload.produto;
		state.contrato = payload.contrato;
		state.perfil = payload.perfil;
		state.token = payload.token;
		state.produtos = payload.produtos;
		state.produtosClienteSelecionado = payload.produtosClienteSelecionado;
		state.contratosClienteSelecionado = payload.contratosClienteSelecionado;
		state.telefone = payload.telefone;
		state.aceiteTermoUso = payload.aceiteTermoUso;
		state.respondeuPesquisa = payload.respondeuPesquisa;
		state.naoLembrarPesquisa = payload.naoLembrarPesquisa;
		state.dataTermoAceito = payload.dataTermoAceito;
		state.horarioNotificacao8h = payload.horarioNotificacao8h;
		state.horarioNotificacao12h = payload.horarioNotificacao12h;
		state.horarioNotificacao17h = payload.horarioNotificacao17h;
	},

	[SET_LOGOUT](state, payload) {
		state = { ...defaultState };
	},

	logout(state) {
		state = { ...defaultState };
	},

	adicionarAvisos(state, payload) {
		state.avisos = payload;
	},

	contratosPerfil(state, payload) {
		state.contratosPerfil = payload;
	},

	todosContratosPerfil(state, payload) {
		state.todosContratosPerfil = payload;
	},

	idClienteSelecionado(state, payload) {
		state.idClienteSelecionado = payload;
	},

	clienteSelecionado(state, payload) {
		state.produtosClienteSelecionado = payload.produtos;
		state.contratosClienteSelecionado = payload.contratos;
	},

	alterarNotificacoesVolumetria(state, payload) {
		state.horarioNotificacao8h = payload.horarioNotificacao8h;
		state.horarioNotificacao12h = payload.horarioNotificacao12h;
		state.horarioNotificacao17h = payload.horarioNotificacao17h;
	},

	autenticado(state, payload) {
		state.token = payload.token;
		state.email = payload.email;
		state.nome = payload.nome;
		state.produtos = payload.produtos;
		state.telefone = payload.telefone;
		state.aceiteTermoUso = payload.aceiteTermoUso;
		state.respondeuPesquisa = payload.respondeuPesquisa;
		state.naoLembrarPesquisa = payload.naoLembrarPesquisa;
		state.perfil = payload.perfil;
		state.dataTermoAceito = payload.dataTermoAceito;
		state.horarioNotificacao8h = payload.horarioNotificacao8h;
		state.horarioNotificacao12h = payload.horarioNotificacao12h;
		state.horarioNotificacao17h = payload.horarioNotificacao17h;
	},

	aceitarTermos(state, payload) {
		state.aceiteTermoUso = payload;
	},

	responderPesquisa(state, payload) {
		state.respondeuPesquisa = payload;
	},

	naoMostrarPesquisa(state, payload) {
		state.naoLembrarPesquisa = payload;
	},

	publicacoesNaoLidas(state, payload) {
		state.publicacoesNaoLidas = payload;
	},

	processosNaoLidos(state, payload) {
		state.processosNaoLidos = payload;
	},

	formularioAtivo(state, payload) {
		state.formularioAtivo = payload;
	},
};
