export default ({ instance }) => {
	return {
		listarTickets(filtro) {
			return instance({
				method: "post",
				url: "atendimento/listar-tickets",
				data: filtro,
			});
		},

		listarAcoesTicket(idTicket) {
			return instance({
				method: "get",
				url: "atendimento/acoes-ticket/" + idTicket,
			});
		},

		novoTicket(ticket) {
			return instance({
				contentType: "multipart/form-data",
				method: "post",
				url: "atendimento/novo-ticket",
				data: ticket,
			});
		},

		responderTicket(resposta) {
			return instance({
				contentType: "multipart/form-data",
				method: "post",
				url: "atendimento/responder-ticket",
				data: resposta,
			});
		},

		ultimasAtualizacoes(filtro) {
			return instance({
				method: "post",
				url: "atendimento/tickets-home",
				data: filtro,
			});
		},

		downloadAnexo(idAnexo) {
			return instance({
				method: "get",
				responseType: "blob",
				url: "atendimento/baixar-anexo/" + idAnexo,
			});
		},

		listaProdutos() {
			return instance({
				method: "get",
				url: "atendimento/consultar-produtos",
			});
		},

		listaTipos() {
			return instance({
				method: "get",
				url: "atendimento/consultar-tipos",
			});
		},

		listaCategorias() {
			return instance({
				method: "get",
				url: "atendimento/consultar-categorias",
			});
		},

		consultarContratos(login) {
			return instance({
				method: "get",
				url: "atendimento/consultar-contrato/" + login,
			});
		},

		consultarContatos(login) {
			return instance({
				method: "get",
				url: "atendimento/consultar-contato/" + login,
			});
		},

		atualizarStatusTicketPorId(id) {
			return instance({
				method: "put",
				url: `atendimento/atualizar-status-ticket/${id}`,
			});
		},

		cancelarTicket(assunto) {
			return instance({
				method: "get",
				url: `atendimento/cancelar-ticket/${assunto}`,
			});
		},
	};
};
