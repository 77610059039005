import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import autenticacao from "@/router/autenticacao";
import principal from "@/router/principal";

const routes = [
	...autenticacao,
	...principal,
	{
		path: "/:pathMatch(.*)*",
		name: "notFound",
		component: () => import("@/views/NotFoundView.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

// router.beforeResolve((to, from, next) => {
// 	const requiresPermission = to.meta.requiresPermission;
// 	if (requiresPermission) {
// 		if (store.getters.getPermissoes[requiresPermission]) {
// 			next();
// 		} else {
// 			next({ name: "home" });
// 		}
// 	} else {
// 		next();
// 	}
// });

router.beforeEach((to, from, next) => {
	if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		!store.getters.isAuthenticated
	) {
		next({
			name: "login",
		});
	} else if (to.name === "login" && store.getters.isAuthenticated) {
		next({ name: "principal" });
	} else {
		next();
	}
});

export default router;
